import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (
          pathName === items[i].pathname ||
          "/" + pathName.split("/")[1] + ("/" + pathName.split("/")[2]) ===
            items[i].pathname
        ) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const history = useHistory();
  const handleLogout = (id) => {
    Swal.fire({
      title: props.t("Are you sure?"),
      text: props.t("You want to Log Out"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#317d9d",
      cancelButtonColor: "#d33",
      confirmButtonText: props.t("Yes, Logout it!"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/logout");
      }
    });
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i> {props.t("Dashboard")}{" "}
            </li>
            <li>
              <Link to="/admin/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i>{" "}
              {props.t("Management")}
            </li>

            <li>
              <Link to="/admin/users">
                <i className="bx bxs-user"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/kyc">
                <i className="bx bx-file"></i>
                <span>{props.t("KYC")}</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i> {props.t("Services")}
            </li>
            <li>
              <Link to="/admin/programmed-savings">
                <i className="bx bx-customize"></i>
                <span>{props.t("Programmed Savings")}</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i> {props.t("Support")}
            </li>
            <li>
              <Link to="/admin/support">
                <i className="bx bx-support"></i>
                <span>{props.t("Support")}</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i> {props.t("Settings")}
            </li>

            <li>
              <Link to="/admin/csm-pages">
                <i className="bx bx-customize"></i>
                <span>{props.t("CMS Pages")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/faqs">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("FAQ")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/profile">
                <i className="bx bx-user"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>

            <li>
              <Link onClick={handleLogout} className="" to={"#"}>
                <i className="bx bx-power-off"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
