import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";
import { alerShow } from "../commonFunction";
import { FILES_URL } from "helpers/api_helper";
import Dropzone from "react-dropzone";
import { fireToast } from "common/toast";
import cloud from "../../assets/images/cloud-file-download.svg";
import pdfImg from "../../assets/images/pdf.png";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { successMsgHandler } from "common/successMsgHandler";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import "../../assets/scss/style.css";
import { uploadKycPdf, getKycPdf } from "services/userServices";
import KycUsers from "./kycUsers";

const Kyc = ({ t }) => {
  //meta title
  // document.title = "Change Password";

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [pdf, setPdf] = useState({
    blob: null,
    src: "",
  });

  const fetchData = () => {
    try {
      getKycPdf().then((res) => {
        setPdf({ ...pdf, src: res?.data?.kycDocument });
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePdfChange = (files) => {
    const extn = ["application/pdf"];
    const [file] = files;
    if (file && extn.includes(file.type)) {
      if (file.size <= 10485760) {
        setPdf({ blob: file, src: window.URL.createObjectURL(file) });
      } else {
        fireToast("error", "File Too Large (10 mb Allowed)");
      }
    } else {
      fireToast("error", "Valid Image File");
    }
  };

  const handleDownload = () => {
    fetch(FILES_URL + pdf?.src) // Replace '/example.pdf' with the actual path to your PDF file
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", FILES_URL + pdf?.src);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values, actions) => {
      if (!pdf.src) {
        fireToast("error", "PDF File is Required");
      } else {
        uploadKycPdf({ kycDocument: pdf.blob })
          .then(function (response) {
            toast.success(t(`${successMsgHandler(response.message)}`));
            actions.resetForm(values);
          })
          .catch(function (error) {
            alerShow("Error", error.response.data.error, "error");
          });
      }
    },
  });
  const uploadRef = useRef(null);

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("KYC")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4 d-flex justify-content-between">
                    {" "}
                    <div>{t("Upload KYC Form")} </div>
                    {pdf?.src && (
                      <div>
                        <button
                          type="button"
                          className="mx-2 btn all-common-btn w-md"
                          onClick={() => window.open(FILES_URL + pdf?.src)}
                        >
                          {t("View")}
                        </button>
                        <button
                          type="button"
                          className="btn all-common-btn w-md"
                          onClick={handleDownload}
                        >
                          {t("Download")}
                        </button>
                      </div>
                    )}
                  </CardTitle>

                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("PDF")}</Label>
                          <div className="text-center">
                            <div className="mb-3 dragdrop-container">
                              <input
                                ref={uploadRef}
                                id="upload"
                                hidden
                                // accept="image/*"
                                accept=".pdf"
                                type="file"
                                onChange={(e) =>
                                  handlePdfChange(e.target.files)
                                }
                              />
                              {pdf.src ? (
                                <img
                                  className="all-img"
                                  src={pdfImg}
                                  alt=""
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                />
                              ) : (
                                <div className="drag-n-drop-container">
                                  <div>
                                    <Dropzone
                                      //   accept="image/*"
                                      accept={{
                                        "application/file": [".pdf"],
                                      }}
                                      multiple={false}
                                      onDrop={(acceptedFiles) => {
                                        handlePdfChange(acceptedFiles);
                                      }}
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <section>
                                          <div
                                            className="drop-area"
                                            {...getRootProps()}
                                          >
                                            <img
                                              width={60}
                                              src={cloud}
                                              alt=""
                                            />
                                            <input
                                              {...getInputProps()}
                                              accept="image/*"
                                              multiple={false}
                                            />

                                            {isDragActive ? (
                                              <div>
                                                {t("Drop your image file here")}
                                              </div>
                                            ) : (
                                              <p>
                                                {t("Drag Click")} <br />
                                                <small className="text-center"></small>
                                              </p>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="fw-bold">
                            {t("Note")}:
                            <span className="text-danger mx-2">
                              {t("Supported format is: pdf only")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Row>

                    <div>
                      <button
                        type="submit"
                        className="btn all-common-btn w-md mt-3"
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4 d-flex justify-content-between"></CardTitle>
                  <KycUsers />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Kyc.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Kyc);
