import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
import { getNotifications } from "services/userServices";

export const CommonContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState();
  const getAllNotifications = () => {
    getNotifications()
      .then((res) => {
        setNotifications(res?.data?.getData);
        setNotificationCount(res?.data?.totalUnRead);
      })
      .catch((err) => {
        console.log(err, "err of user data ");
        setNotificationCount([]);
      });
  };

  return (
    <CommonContext.Provider
      value={{
        notificationCount,
        notifications,
        setNotifications,
        getAllNotifications,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default NotificationContextProvider;
