import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import PropTypes, { element, func } from "prop-types";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import axios from "axios";
import { alerShow } from "../commonFunction";
import { apiRoute } from "routes/apiRoutes";
// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/style.scss";
import { useEffect } from "react";
import { csmUpdateFlow, getCsmFlow } from "services/userServices";
import { successMsgHandler } from "common/successMsgHandler";

const FormElement = ({ t }) => {
  const [formValues, setFormValues] = useState({
    title: "",
    titleSp: "",
    description: "",
    descriptionSp: "",
    type: "",
  });

  const changeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleDescription = (editorState) => {
    setFormValues({ ...formValues, ["description"]: editorState });
  };
  const handleDescriptionSp = (editorState) => {
    setFormValues({ ...formValues, ["descriptionSp"]: editorState });
  };
  const handleCsmType = async (event) => {
    await getCSMcontent(event.target.value);
  };

  useEffect(() => {
    getCSMcontent("privacy_policy");
  }, []);

  const getCSMcontent = async (type) => {
    await getCsmFlow(type)
      .then(function (response) {
        var Result = response.data;

        setFormValues({
          title: Result?.title ? Result?.title : "",
          titleSp: Result?.titleSp ? Result?.titleSp : "",
          description: Result?.description ? Result?.description : "",
          descriptionSp: Result?.descriptionSp ? Result?.descriptionSp : "",
          type: type,
        });
      })
      .catch(function (error) {
        // alerShow('Error', error.response.data.message, 'error');
      });
  };
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: formValues.description,
      descriptionSp: formValues?.descriptionSp,
      title: formValues.title,
      titleSp: formValues.titleSp,
      type: formValues.type,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Required")),
      titleSp: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      description: Yup.string().required(t("Required")),
      descriptionSp: Yup.string().required(t("Required")),
    }),
    onSubmit: (values) => {
      updateCSMcontent(values);
    },
  });

  const updateCSMcontent = async (data) => {
    // await axios.post(apiRoute.csmUpdate, data)
    await csmUpdateFlow(data)
      .then(function (response) {
        toast.success(t(`${successMsgHandler(response.message)}`));
      })
      .catch(function (error) {
        console.log(error, "eeeee");
        alerShow("Error", error?.response?.data?.message, "error");
      });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{t("CMS Pages")}</CardTitle>
                  <form
                    className="outer-repeater custom-error-val"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            className="col-form-label col-lg-2"
                            style={{ paddingLeft: "5rem" }}
                          >
                            {t("Select Page Type")}
                          </Label>
                          <Col lg="10">
                            <select
                              className="form-control"
                              name="type"
                              onBlur={validation.handleBlur}
                              onChange={handleCsmType}
                            >
                              {/* <option value="">Select Type</option> */}
                              <option value="privacy_policy">
                                {t("Privacy Policy")}
                              </option>
                              <option value="about_us">{t("About Us")}</option>
                              <option value="term_conditions">
                                {t("Term and Conditions")}
                              </option>
                              {/* <option value="community_rules">Community Rules</option> */}
                            </select>
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            className="col-form-label col-lg-2"
                            style={{ paddingLeft: "5rem" }}
                          >
                            {t("Title")} ({t("In English")})
                          </Label>

                          <Col lg="10">
                            <Row>
                              <Col md={12} className="pr-0">
                                <input
                                  type="text"
                                  name="title"
                                  className="inner form-control"
                                  placeholder=""
                                  onBlur={validation.handleBlur}
                                  onChange={changeValues}
                                  value={formValues.title}
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            className="col-form-label col-lg-2"
                            style={{ paddingLeft: "5rem" }}
                          >
                            {t("Title")} ({t("In Spanish")})
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col md={12} className="pr-0">
                                <input
                                  type="text"
                                  name="titleSp"
                                  className="inner form-control"
                                  placeholder=""
                                  onBlur={validation.handleBlur}
                                  onChange={changeValues}
                                  value={formValues.titleSp}
                                />
                                {validation.touched.titleSp &&
                                validation.errors.titleSp ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.titleSp}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            className="col-form-label col-lg-2"
                            style={{ paddingLeft: "5rem" }}
                          >
                            {t("Description")} ({t("In English")})
                          </Label>
                          <Col lg="10 custom-form">
                            <JoditEditor
                              name="description"
                              id="description"
                              rows="5"
                              tabIndex={1} // tabIndex of textarea
                              onBlur={validation.handleBlur}
                              onChange={(newContent) =>
                                handleDescription(newContent)
                              }
                              value={formValues.description}
                            />
                            {/* <Editor
                                                            name="description"
                                                            id="description"
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            placeholder="Place Your Content Here..."
                                                            onBlur={validation.handleBlur}
                                                            // onChange={handleDescription}
                                                            onEditorStateChange={handleDescription}
                                                        /> */}
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            className="col-form-label col-lg-2"
                            style={{ paddingLeft: "5rem" }}
                          >
                            {t("Description")} ({t("In Spanish")})
                          </Label>

                          <Col lg="10 custom-form">
                            <JoditEditor
                              name="descriptionSp"
                              id="descriptionSp"
                              rows="5"
                              tabIndex={1} // tabIndex of textarea
                              onBlur={validation.handleBlur}
                              onChange={(newContent) =>
                                handleDescriptionSp(newContent)
                              }
                              value={formValues.descriptionSp}
                            />
                            {validation.touched.descriptionSp &&
                            validation.errors.descriptionSp ? (
                              <FormFeedback type="invalid">
                                {validation.errors.descriptionSp}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button
                          type="submit"
                          className="all-common-btn"
                          color="primary"
                        >
                          {t("Save")}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

FormElement.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(FormElement);
