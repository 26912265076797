import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Admin Dashboard
import AdminCSMpages from "../admin/csm/index";
import AdminSavingService from "../admin/saving-service/index";
import AdminDashboard from "../admin/dashboard/index";
import AdminChangePassword from "../admin/account/index";
import AdminUsers from "../admin/users/index";
import Kyc from "admin/users/kyc";
import AdminUserDetail from "../admin/users/detail";
import ServiceDetail from "admin/users/detail/ServiceDetail";
import Faq from "../admin/faq/Faq";
import ResetPassword from "pages/Authentication/ResetPassword";
import support from "admin/support";

const authProtectedRoutes = [
  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  // { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/csm-pages", component: AdminCSMpages },
  { path: "/admin/support", component: support },
  { path: "/admin/programmed-savings", component: AdminSavingService },
  { path: "/admin/profile", component: AdminChangePassword },
  { path: "/admin/users", component: AdminUsers },
  { path: "/admin/kyc", component: Kyc },
  { path: "/admin/users/:userID", component: AdminUserDetail },
  { path: "/admin/users/detail/:serviceID", component: ServiceDetail },
  { path: "/admin/faqs", component: Faq },

  { path: "/admin/dashboard", component: AdminDashboard },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admin/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  // { path: "/register", component: Register },
  // { path: "*", component: staking },
];

export { authProtectedRoutes, publicRoutes };
