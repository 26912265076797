import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { getUserGraphData } from "services/userServices";
import { withTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";

const ColumnWithDataLabels = ({ dataColors, t }) => {
  const [series1Data, setSeries1Data] = useState([]);
  const [series1xasis, setSeries1xasis] = useState([]);

  const getDashboardInfo = async () => {
    try {
      let response = await getUserGraphData();

      if (response.status == 200) {
        setSeries1Data(
          response?.data?.monthlyUserCounts?.map((item) =>
            item.userCount?.toFixed(2)
          )
        );
        setSeries1xasis(
          response?.data?.monthlyUserCounts?.map((item) =>
            item?.month?.substring(0, 3)
          )
        );
      }
    } catch (error) {
      // toastCatchError(error)
    }
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);

  var barChartColor = getChartColorsArray(dataColors);
  const data = {
    labels: series1xasis,
    datasets: [
      {
        label: t("Monthly Registered Users"),
        backgroundColor: barChartColor[0],
        borderColor: barChartColor[0],
        borderWidth: 1,
        hoverBackgroundColor: barChartColor[1],
        hoverBorderColor: barChartColor[1],
        data: series1Data,
      },
    ],
  };
  const option = {
    scales: {
      dataset: [
        {
          // barPercentage: 0.4,
        },
      ],
    },
  };

  return (
    <div>
      <div id="chart">
        <Bar height={285} data={data} options={option} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
export default withTranslation()(ColumnWithDataLabels);
