import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FILES_URL } from "helpers/api_helper";
import {
  getOneUserTransactions,
  getOneInvestedPlans,
} from "services/transactionServices";
import { Modal } from "react-bootstrap";
import { savingServiceDetailOneServiceOfUser } from "services/userServices";
import PropTypes from "prop-types";
import {
  dateTimeformat,
  dateformat,
  handleClickCopy,
  listingOfMyStaking,
  longStringConvertToShort,
} from "admin/commonFunction";
import avatar from "../../../assets/images/users/dummy.png";
import img3 from "../../../assets/images/background-user.png";
import { Link } from "react-router-dom";
import { BlockChainContext } from "context/blockChainContext";
import { ethers } from "ethers";
import { config } from "config/blockChainConfig";
import noKYC from "../../../assets/images/nokyc.jpg";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fireToast } from "common/toast";
import { dateFormat, dateOnlyFormat } from "common/utility";
import { Preview } from "./preview";

const UserDetail = ({ t }) => {
  //meta title
  // document.title = "User Detail"

  const location = useLocation();
  const { userDetail } = location.state;

  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [planCurrentPage, setPlanCurrentPage] = useState(1);
  const [selectedPreview, setSelectedPreview] = useState();
  const [previewStatus, setPreviewStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [planTotalCount, setPlanTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [planLoaderStatus, setPlanLoaderStatus] = useState(true);
  const [transListing, setTransListing] = useState([]);
  const [planListing, setPlanListing] = useState([]);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  const handleClose = () => {
    setShow(false);
  };

  const fetchTransactions = (userId, pagenumber, pagelimit) => {
    try {
      setLoaderStatus(true);
      getOneUserTransactions(userId, pagenumber, pagelimit).then((res) => {
        setTransListing(res?.data?.items);
        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  const fetchPurchasedPlans = (id, pagenumber, pagelimit) => {
    try {
      setPlanLoaderStatus(true);
      getOneInvestedPlans(id, pagenumber, pagelimit).then((res) => {
        setPlanListing(res?.data?.services);
        let pageCount = Math.ceil(res.data.totalCount / perPage);
        setPlanTotalCount(pageCount);
        setPlanLoaderStatus(false);
      });
    } catch (err) {
      setPlanLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTransactions(userDetail?._id, currentPage, perPage);
  }, []);
  useEffect(() => {
    fetchPurchasedPlans(userDetail?._id, planCurrentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchTransactions(userDetail?._id, page, perPage);
  };

  const handlePlanPageClick = (page) => {
    setPlanCurrentPage(page);
    fetchPurchasedPlans(userDetail?._id, page, perPage);
  };

  function convertMonthsToYears(months) {
    let years = Math.floor(months / 12); // Get the number of whole years
    let remainingMonths = months % 12; // Get the remaining months

    if (years === 0) {
      return `${remainingMonths} ${t("months")}`;
    } else if (years === 1 && remainingMonths === 0) {
      return `1 ${t("year")}`;
    } else if (years === 1) {
      return `1 ${t("year")} ${remainingMonths} ${t("months")}`;
    } else if (remainingMonths === 0) {
      return `${years} ${t("years")}`;
    } else {
      return `${years} ${t("years")} ${remainingMonths} ${t("months")}`;
    }
  }

  const handleShowView = (item) => {
    savingServiceDetailOneServiceOfUser(userDetail?._id, item?._id)
      .then((res) => {
        setDetailData(res?.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Users")}
            titlePath="/admin/users"
            breadcrumbItem={t("User Detail")}
          />

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <img
                          src={
                            userDetail?.image
                              ? FILES_URL + userDetail?.image
                              : avatar
                          }
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>

                      <div className="text-muted">
                        <h5>
                          {" "}
                          {userDetail
                            ? userDetail?.firstName + " " + userDetail?.lastName
                            : ""}{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-4">
                  <ul className="list-unstyled mt-2">
                    <li className="mt-3">
                      <div className="d-flex">
                        <i
                          className="bx bx-phone text-primary fs-4"
                          style={{ color: "#317d9d" }}
                        ></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">{t("Phone")}</h6>
                          <p className="text-muted fs-14 mb-0">
                            {" "}
                            {userDetail?.mobileNo}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">{t("Email")}</h6>
                          <p className="text-muted fs-14 mb-0">
                            {userDetail?.email}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                    {/* <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Location</h6>
                          <p className="text-muted fs-14 mb-0">
                            {userDetail?.city}
                          </p>
                        </div>
                      </div>
                    </li> */}
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-globe text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">{t("Created On")}</h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            {" "}
                            {dateformat(userDetail?.createdAt)}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Row>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-warning bg-soft text-warning">
                            <i className="bx bx-dollar h3 mb-0" />
                          </span>
                        </div>

                        <div className="flex-grow-1">
                          <p className="text-muted mb-2"> {t("Balance")} </p>
                          <h5 className="mb-0">
                            $ {userDetail?.balance?.toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-danger bg-soft text-danger">
                            <i className="bx bxs-layer h3 mb-0" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Staking Investment</p>
                          <h5 className="mb-0">
                            1{" "}
                            <span className="font-size-14 text-muted">
                              = $ {1}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-success bg-soft text-success">
                            <i className="fas fa-chart-line h4 mb-0" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Staking Maturity</p>
                          <h5 className="mb-0">
                            {1}{" "}
                            <span className="font-size-14 text-muted">
                              = $ {1}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>

              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <h4 className="card-title mb-3">{t("KYC Status")}</h4>
                    </Col>
                    <Col md={6} className="text-end"></Col>
                    <Col md={12}>
                      <div className="text-center my-2">
                        <img src={noKYC} height={115} />
                        <h5>
                          {" "}
                          {t("KYC")} {t(userDetail?.isKYCConfirmed)}{" "}
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  {/* <h4 className="card-title mb-3">User Earning</h4> */}
                  {/* <div>
                                        <div id="overview-chart" dir="ltr">
                                            <JobWidgetCharts graphStake={graphStake} />
                                        </div>
                                    </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Programmed Saving Service Plans")}{" "}
                  </h4>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No.</th>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("Amount")}</th>
                          <th scope="col">{t("Interest")}</th>
                          <th scope="col">{t("Tenure")}</th>
                          <th scope="col">{t("Locking Period")}</th>
                          <th scope="col">{t("Early Withdrawal Penalty")}</th>
                          <th scope="col">{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {planLoaderStatus ? (
                          <tr>
                            <td colSpan="8"> {t("Loading")} ... </td>
                          </tr>
                        ) : planListing?.length != 0 ? (
                          planListing?.map((element, index) => {
                            planCurrentPage > 1
                              ? (incermentInd =
                                  (planCurrentPage - 1) * perPage + 1)
                              : 0;
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="co-name">
                                    {" "}
                                    {incermentInd + index}{" "}
                                  </span>
                                </td>
                                <td>{element?.service?.name}</td>
                                <td>${element?.amount}</td>
                                <td>{element?.service?.interestRate}%</td>
                                <td>
                                  {convertMonthsToYears(
                                    element?.service?.tenure
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {convertMonthsToYears(
                                    element?.service?.lockingPeriod
                                  )}
                                </td>

                                <td>
                                  {" "}
                                  {element?.service?.earlyWithdrawalPenalty}%
                                </td>
                                <td>
                                  <ul className="list-unstyled hstack gap-1 mb-0">
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/admin/users/detail/service-detail`,
                                          state: { userDetail: element },
                                        }}
                                        className="btn btn-info"
                                      >
                                        <i className="mdi mdi-eye" />
                                      </Link>
                                    </li>
                                  </ul>
                                  {/* <Button
                                    type="button"
                                    className="all-common-btn"
                                    color="primary"
                                    onClick={() => handleShowView(element)}
                                  >
                                    {t("View Detail")}
                                  </Button> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9}>{t("No Records Found")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Row className="mt-2">
                      <Col md={9}></Col>
                      <Col md={3}>
                        <Pagination
                          {...bootstrap5PaginationPreset}
                          current={planCurrentPage}
                          total={planTotalCount}
                          onPageChange={(page) => handlePlanPageClick(page)}
                          className="pagination justify-content-end"
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    {" "}
                    {t("Transaction History")}{" "}
                  </h4>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No.</th>
                          <th scope="col">{t("Transaction Id")}</th>
                          <th scope="col">{t("Amount")}</th>
                          <th scope="col">{t("Type")}</th>
                          <th scope="col">{t("Medium")}</th>
                          <th scope="col">{t("status")}</th>
                          <th>{t("Date")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loaderStatus ? (
                          <tr>
                            <td colSpan="7"> {t("Loading")} ... </td>
                          </tr>
                        ) : transListing?.length != 0 ? (
                          transListing.map((element, index) => {
                            currentPage > 1
                              ? (incermentInd = (currentPage - 1) * perPage + 1)
                              : 0;
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="co-name">
                                    {" "}
                                    {incermentInd + index}{" "}
                                  </span>
                                </td>
                                <td>{element._id}</td>
                                <td>$ {element?.amount?.toFixed(2)}</td>
                                <td>
                                  {element?.transactionType.replace("_", " ")}
                                </td>
                                <td>
                                  {" "}
                                  {element?.paymentMedium?.replace("_", " ")}
                                </td>
                                <td>
                                  <span
                                    className={`me-1 p-2 badge bg-${
                                      element?.status == "PENDING"
                                        ? "success"
                                        : element?.status == "FAILED"
                                        ? "danger"
                                        : "info"
                                    }`}
                                  >
                                    {element?.status === "PENDING"
                                      ? t("COMPLETED")
                                      : element?.status}
                                  </span>
                                </td>
                                <td>{dateformat(element.createdAt)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>{t("No Records Found")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Row className="mt-2">
                      <Col md={9}></Col>
                      <Col md={3}>
                        <Pagination
                          {...bootstrap5PaginationPreset}
                          current={currentPage}
                          total={totalCount}
                          onPageChange={(page) => handlePageClick(page)}
                          className="pagination justify-content-end"
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            // backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Program Saving Service Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className="col-6">
                    <h6>Installment Amount</h6>
                  </div>
                  <div className="col-6">
                    <p>${detailData?.installmentAmount}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h6>Interest Amount</h6>
                  </div>
                  <div className="col-6">
                    <p>${detailData?.interestAmount?.toFixed(2)}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h6>Last Paid</h6>
                  </div>
                  <div className="col-6">
                    <p>{moment(detailData?.lastPaymentDate).format("lll")}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h6>Total Installment Paid</h6>
                  </div>
                  <div className="col-6">
                    <p>{detailData?.totalInstallmentPaid}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="col-6">
                    <p>${detailData?.totalAmount}</p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Preview
            selectedPreview={selectedPreview}
            previewStatus={previewStatus}
            setPreviewStatus={setPreviewStatus}
            setSelectedPreview={setSelectedPreview}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

UserDetail.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserDetail));
