import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { LoaderContext } from "context/ContextProvider";
import {
  addNewFaq,
  deleteFaqData,
  getFaqDetail,
  updateNewFaq,
} from "services/userServices";
import { alerShow } from "admin/commonFunction";
import Loader from "common/loader";

function Faq({ t }) {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  const language = localStorage.getItem("i18nextLng");
  const [loading, loadingSet] = useState(false);
  const [Data, setdata] = useState();
  const [editVals, setEditVals] = useState({
    id: "",
    question: "",
    questionSp: "",
    answers: "",
    answerSp: "",
  });
  const handleFaq = async (values) => {
    loadingSet(true);
    try {
      await addNewFaq({
        question: values.question,
        questionSp: values.questionSp,
        answers: values.answers,
        answerSp: values.answerSp,
      }).then((res) => {
        loadingSet(false);
        alerShow("Success", "FAQ Added Successfully", "success");
        handleClose();
        fetchData();
      });
    } catch (err) {
      console.log(err);
      loadingSet(false);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const editFAQ = (id, question, questionSp, answers, answerSp) => {
    handleShow();
    setEditVals({ id, question, questionSp, answers, answerSp });
  };

  const updateFAQ = async (e) => {
    e.preventDefault();
    let bodyData = {
      id: editVals.id,
      question: editVals.question,
      questionSp: editVals.questionSp,
      answers: editVals.answers,
      answerSp: editVals.answerSp,
    };
    loadingSet(true);
    try {
      await updateNewFaq({
        question: bodyData.question,
        questionSp: bodyData.questionSp,
        answers: bodyData.answers,
        answerSp: bodyData.answerSp,
        id: editVals.id,
      }).then((res) => {
        loadingSet(false);
        handleClose();
        alerShow("Success", "FAQ Updated Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      loadingSet(false);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const fetchData = async () => {
    try {
      loadingSet(true);
      await getFaqDetail().then((res) => {
        setdata(res.data.items);
        loadingSet(false);
      });
    } catch (err) {
      loadingSet(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteFAQ = async (faq_id) => {
    try {
      loadingSet(true);
      await deleteFaqData(faq_id).then((res) => {
        alerShow("Success", "FAQ Deleted Successfully", "success");
        loadingSet(false);
        fetchData();
      });
    } catch (err) {
      console.log(err);
      loadingSet(false);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const ConfirmDelete = (id) => {
    // let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFAQ(id);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      question: "",
      questionSp: "",
      answers: "",
      answerSp: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required(t("Required")),
      questionSp: Yup.string().required(t("Required")),
      answers: Yup.string().required(t("Required")),
      answerSp: Yup.string().required(t("Required")),
    }),
    onSubmit: (values) => {
      handleFaq(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [Add]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      {t("Frequently Asked Questions")}
                    </h5>
                    <div style={{ marginRight: "0px !important" }}>
                      <button
                        type="button"
                        className="btn mt-3 all-common-btn"
                        onClick={handelShow1}
                      >
                        <i className="bx bx-plus"></i> {t("Add FAQ")}
                      </button>
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ padding: 16 }}>
                  <div className="table-responsive">
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th>{t("Question")}</Th>
                          <Th>{t("Answer")}</Th>
                          <Th>{t("Action")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Data?.map((element, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ padding: 35 }}>
                                <span className="co-name">{index + 1}</span>
                              </td>
                              <td>
                                {language === "sp"
                                  ? element.questionSp
                                  : element.question}
                              </td>
                              <td>
                                {" "}
                                {language === "sp"
                                  ? element.answerSp
                                  : element.answers}
                              </td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <button
                                      onClick={() =>
                                        editFAQ(
                                          element?._id,
                                          element?.question,
                                          element?.questionSp,
                                          element?.answers,
                                          element?.answerSp
                                        )
                                      }
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </button>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => {
                                        ConfirmDelete(element._id);
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add FAQ")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Question")} ({t("In English")})
              </Form.Label>
              <Form.Control
                type="text"
                name="question"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question}
                placeholder=""
                autoFocus="true"
              />
              {formik.touched.question && formik.errors.question ? (
                <div style={{ color: "red" }}>{formik.errors.question}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Question")} ({t("In Spanish")})
              </Form.Label>
              <Form.Control
                type="text"
                name="questionSp"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.questionSp}
                placeholder=""
                autoFocus="true"
              />
              {formik.touched.questionSp && formik.errors.questionSp ? (
                <div style={{ color: "red" }}>{formik.errors.questionSp}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Answer")} ({t("In English")})
              </Form.Label>
              <Form.Control
                as="textarea"
                name="answers"
                rows="8"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.answers}
                placeholder=""
                aria-label="With textarea"
              />
              {formik.touched.answers && formik.errors.answers ? (
                <div style={{ color: "red" }}>{formik.errors.answers}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Answer")} ({t("In Spanish")})
              </Form.Label>
              <Form.Control
                as="textarea"
                name="answerSp"
                rows="8"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.answerSp}
                placeholder=""
                aria-label="With textarea"
              />
              {formik.touched.answerSp && formik.errors.answerSp ? (
                <div style={{ color: "red" }}>{formik.errors.answerSp}</div>
              ) : null}
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="all-common-btn"
              style={{ marginLeft: 10 }}
            >
              {t("Add")}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={Edit}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit FAQ")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updateFAQ}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Question")} ({t("In English")})
              </Form.Label>
              <Form.Control
                type="text"
                value={editVals ? editVals.question : ""}
                onChange={(e) => {
                  setEditVals({ ...editVals, question: e.target.value });
                }}
                name="question"
                placeholder=""
                autoFocus="true"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Question")} ({t("In Spanish")})
              </Form.Label>
              <Form.Control
                type="text"
                value={editVals ? editVals.questionSp : ""}
                onChange={(e) => {
                  setEditVals({ ...editVals, questionSp: e.target.value });
                }}
                name="questionSp"
                placeholder=""
                autoFocus="true"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Answer")} ({t("In English")})
              </Form.Label>
              <Form.Control
                as="textarea"
                value={editVals ? editVals.answers : ""}
                onChange={(e) => {
                  setEditVals({ ...editVals, answers: e.target.value });
                }}
                name="answers"
                aria-label="With textarea"
                rows={8}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {t("Answer")} ({t("In Spanish")})
              </Form.Label>
              <Form.Control
                as="textarea"
                value={editVals ? editVals.answerSp : ""}
                onChange={(e) => {
                  setEditVals({ ...editVals, answerSp: e.target.value });
                }}
                name="answerSp"
                aria-label="With textarea"
                rows={8}
              />
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button variant="primary" type="submit" style={{ marginLeft: 10 }}>
              {t("Update")}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
Faq.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Faq));
