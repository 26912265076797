/* eslint-disable react/no-unknown-property */
import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import { Modal, Form, Button } from "react-bootstrap";
import avatar from "../../assets/images/users/dummy.png";
import { CInputGroup, CInputGroupText } from "@coreui/react";
import Schedule from "../../assets/images/Schedule.svg";
import { StandardPickerDateFormat } from "common/utility";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css";
import { withTranslation } from "react-i18next";
import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { getAllQueries, queryStatusChange } from "services/userServices";
import { FILES_URL } from "helpers/api_helper";
import { successMsgHandler } from "common/successMsgHandler";

const Support = ({ t }) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  // document.title = "Users";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dateFilter, setDateFilter] = useState([]);
  const [listingData, setData] = useState([]);
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = (
    pagenumber,
    pagelimit,
    search = "",
    status = "",
    dateFilter = []
  ) => {
    try {
      setLoaderStatus(true);
      const [fromDate, toDate] = dateFilter || [];
      // axios
      //   .get(
      //     apiRoute.getUserListing +
      //       "?page=" +
      //       pagenumber +
      //       "&limit=" +
      //       pagelimit +
      //       "&search=" +
      //       search +
      //       "&userStatus=" +
      //       status
      //   )

      getAllQueries(
        pagenumber,
        pagelimit,
        search,
        status,
        fromDate || "",
        toDate || ""
      ).then((res) => {
        setData(res.data.items);

        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName, statusFilter, dateFilter);
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value, statusFilter, dateFilter);
    }, 500);

    setTypingTimeout(timeoutId);

    // setSearchName(event.target.value)
    // setCurrentPage(1)
    // fetchData(1, perPage, event.target.value, statusFilter)
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    if (e.target.value != "") {
      fetchData(1, perPage, searchName, e.target.value, dateFilter);
    } else {
      fetchData(1, perPage, searchName, "", dateFilter);
    }
  };
  const dateFilterChange = (e, picker) => {
    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ];
    setDateFilter(filter);
    fetchData(1, perPage, searchName, statusFilter, filter);
  };

  const deleteUser = (user_id) => {
    try {
      axios.delete(apiRoute.deleteUser + `?userID=${user_id}`).then((res) => {
        toast.success("User Deleted Successfully");
        fetchData(1, perPage);
      });
    } catch (err) {
      console.log(err);
      toast.error("An error occurred !");
    }
  };

  //delete Job
  const ConfirmDeleteUser = (userId) => {
    // let URL = BASE_URL + "user/delete/" + userId
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const handleChangeStatus = async (id, check) => {
    queryStatusChange({
      status: check ? "APPROVED" : "PENDING",
      id,
    })
      .then(function (response) {
        toast.success(t(`${successMsgHandler(response.message)}`));
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
      });
  };
  const inputRef = useRef(null);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient">
                          {" "}
                          {t("Queries")}{" "}
                        </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                {t("Search this table")}
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`${t("Search")}...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>

                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleStatusFilter}
                          >
                            <option value="">{t("All Queries")}</option>
                            <option value="PENDING">{t("Pending")}</option>
                            <option value="APPROVED">{t("Resolved")}</option>
                          </select>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <label htmlFor="search-bar-0" className="w-100">
                            <span id="search-bar-0-label" className="sr-only">
                              {t("Filter by date")}
                            </span>
                            <CInputGroup className="">
                              <DateRangePicker
                                // initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                                onApply={dateFilterChange}
                                // onCancel={}
                                onShow={() => setCalendarIsShowing(true)}
                                onHide={() => setCalendarIsShowing(false)}
                              >
                                <input
                                  ref={inputRef}
                                  placeholder={t("Filter by Date")}
                                  className="form-control"
                                  readOnly={true}
                                  value={
                                    dateFilter.length
                                      ? `${StandardPickerDateFormat(
                                          dateFilter[0]
                                        )} - ${StandardPickerDateFormat(
                                          dateFilter[1]
                                        )}`
                                      : ""
                                  }
                                />
                              </DateRangePicker>
                              <CInputGroupText>
                                <img
                                  onClick={() => {
                                    if (
                                      !calendarIsShowing &&
                                      dateFilter.length
                                    ) {
                                      setDateFilter([]);

                                      fetchData(
                                        1,
                                        perPage,
                                        searchName,
                                        statusFilter,
                                        []
                                      );
                                    }
                                  }}
                                  src={
                                    calendarIsShowing
                                      ? calendarremovelines
                                      : dateFilter.length
                                      ? calendarremovelines
                                      : Schedule
                                  }
                                  alt=""
                                  width={20}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>{t("Name")}</Th>
                        <Th>{t("Email")}</Th>
                        <Th>{t("Subject")}</Th>
                        <Th>{t("Query")}</Th>
                        <Th>{t("Change Status")}</Th>
                        <Th>{t("Created At")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="7"> {t("Loading")} ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>{element.name}</td>

                              <td>{element.email}</td>
                              <td>{element.subject}</td>
                              <td>{element.message}</td>
                              {/* <td>
                                <Badge
                                  color={
                                    element.isKYCConfirmed == "PENDING"
                                      ? "warning"
                                      : element.isKYCConfirmed == "APPROVED"
                                      ? "success"
                                      : element?.isKYCConfirmed == "REJECTED"
                                      ? "danger"
                                      : "info"
                                  }
                                  className="me-1 p-1"
                                >
                                  {element.status}
                                </Badge>
                              </td> */}

                              <td>
                                <div className="square-switch mytoggle">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${element._id}`}
                                    switch="success"
                                    defaultChecked={
                                      element.status == "PENDING" ? false : true
                                    }
                                    value={element._id}
                                    onClick={(e) =>
                                      handleChangeStatus(
                                        element?._id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`square-switch${element._id}`}
                                    data-on-label={t("Resolved")}
                                    data-off-label={t("Pending")}
                                  />
                                </div>
                              </td>
                              <td>{dateformat(element.createdAt)}</td>
                              {/* <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to={{
                                        pathname: `/admin/users/detail`,
                                        state: { userDetail: element },
                                      }}
                                      className="btn btn-sm all-common-btn"
                                    >
                                      <i className="mdi mdi-eye" />
                                    </Link>
                                  </li>
                                </ul>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>{t("No Records Found")}</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

Support.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Support);
