import { get, post } from "helpers/api_helper";

export const addCommision = (data) => {
  return post("/admin/addCommision", data);
};
export const getAllCommision = () => {
  return get("/admin/getAllCommision");
};

export const getOneUserTransactions = (userId, pageNumber, pageSize) => {
  return get(
    `/transaction/getAllClientTransactionsByAdmin?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const getOneInvestedPlans = (id, pageNumber, pageSize) => {
  return get(
    `/admin/getUserInvestedServicesByAdmin?id=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
