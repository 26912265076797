export const errorMsgHandler = (err) => {
  return err.response.data
    ? err.response.data.error === "Password seems to be incorrect"
      ? "Password seems to be incorrect"
      : err.response.data.error === "Admin doesn't exists!"
      ? "Admin doesn't exists!"
      : err.response.data.error === "Invalid Email"
      ? "Invalid Email"
      : err.response.data.error === "OTP does not match"
      ? "OTP does not match"
      : err.response.data.error === "USER_ALREADY_EXISTS"
      ? "User Already Exist"
      : err.response.data.error === "EMAIL_ALREADY_EXISTS"
      ? "Email Already Exist"
      : err.response.data.error === "NUMBER_ALREADY_EXISTS"
      ? "Number Already Exist"
      : err.response.data.error === "USERNAME_ALREADY_EXISTS"
      ? "Username Already Exist"
      : err.response.data.error === "NAME_ALREADY_EXISTS"
      ? "Name Already Exist"
      : err.response.data.error === "INVAILD_USERNAME"
      ? "Invalid User Name"
      : err.response.data.error === "INVALID_DOCUMENT"
      ? "Invalid Document"
      : err.response.data.error === "INVALID_CATEGORY"
      ? "Invalid Category"
      : err.response.data.error === "USERNAME_NOT_REGISTERED"
      ? "Username Not Registered"
      : err.response.data.error === "BLOCKED_BY_ADMIN"
      ? "Blocked By Admin"
      : err.response.data.error === "User_not_found!!"
      ? "User not found"
      : err.response.data.error === "kyc/kyb_not_completed_yet!!"
      ? "KYC / KYB not completed"
      : err.response.data.error
    : err.code === "ERR_NETWORK"
    ? "Network Error"
    : err.message
    ? err.message
    : err;
};
