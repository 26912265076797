import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import StackedColumnChart from "./StackedColumnChart";
import classNames from "classnames";
import {
  getWithdrawGraphData,
  getDepositGraphData,
} from "services/userServices";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import { withTranslation } from "react-i18next";

const DepositWithraw = ({ t }) => {
  const apexCardUserChartColors = getChartColorsArray('["#317d9d", "#2fe74a"]');

  const [periodData, setPeriodData] = useState([]);
  const [seriesRdData, setSeriesRdData] = useState([]);
  const [seriesRdxasis, setSeriesRdxasis] = useState([]);
  const [seriesWalletData, setSeriesWalletData] = useState([]);
  const [seriesWalletxasis, setSeriesWalletxasis] = useState([]);
  const [series1Data, setSeries1Data] = useState([]);
  const [series1xasis, setSeries1xasis] = useState([]);
  const [periodType, setPeriodType] = useState("monthly");
  const onChangeChartPeriod = (pType) => {
    setPeriodType(pType);
  };

  const getDashboardDepositData = async () => {
    try {
      let response = await getDepositGraphData();

      if (response.status == 200) {
        setSeriesWalletData(
          response?.data?.monthlyDepositCounts?.map((item) =>
            item.totalBalance?.toFixed(2)
          )
        );
        setSeriesWalletxasis(
          response?.data?.monthlyDepositCounts?.map((item) =>
            item?.month?.substring(0, 3)
          )
        );
        setSeriesRdData(
          response?.data?.monthlyFdCounts?.map((item) =>
            item.totalBalance2?.toFixed(2)
          )
        );
        setSeriesRdxasis(
          response?.data?.monthlyFdCounts?.map((item) =>
            item?.month?.substring(0, 3)
          )
        );
      }
    } catch (error) {
      // toastCatchError(error)
    }
  };
  const getDashboardInfo = async () => {
    try {
      let response = await getWithdrawGraphData();

      if (response.status == 200) {
        setSeries1Data(
          response?.data?.monthlyWithdrawCounts?.map((item) =>
            item.totalBalance?.toFixed(2)
          )
        );
        setSeries1xasis(
          response?.data?.monthlyWithdrawCounts?.map((item) =>
            item?.month?.substring(0, 3)
          )
        );
      }
    } catch (error) {
      // toastCatchError(error)
    }
  };

  useEffect(() => {
    getDashboardInfo();
    getDashboardDepositData();
  }, []);

  const series = [
    {
      name: t("RD"),
      data: seriesRdData,
    },
    {
      name: t("Wallet"),
      data: seriesWalletData,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: seriesRdxasis,
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  const series1 = [
    {
      name: t("Withdraw"),
      data: series1Data,
    },
  ];

  const options1 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: series1xasis,
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={4} sm="4" className="pe-0">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title me-2">{t("Deposit")}</h5>
                <div className="ms-auto">
                  {/* <div className="toolbar d-flex flex-wrap gap-2 text-end">
                    <button type="button" className="btn btn-light btn-sm">
                      ALL
                    </button>{" "}
                    <button type="button" className="btn btn-light btn-sm">
                      1M
                    </button>{" "}
                    <button type="button" className="btn btn-light btn-sm">
                      6M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-light btn-sm active"
                    >
                      1Y
                    </button>{" "}
                  </div> */}
                </div>
              </div>
              <hr className="mb-4" />
              <div id="area-chart" dir="ltr">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height={350}
                  className="apex-charts"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} sm="4" className="pe-0">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h5 className="card-title me-2">{t("Withdraw")}</h5>
                <div className="ms-auto">
                  {/* <div className="toolbar d-flex flex-wrap gap-2 text-end">
                    <button type="button" className="btn btn-light btn-sm">
                      ALL
                    </button>{" "}
                    <button type="button" className="btn btn-light btn-sm">
                      1M
                    </button>{" "}
                    <button type="button" className="btn btn-light btn-sm">
                      6M
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-light btn-sm active"
                    >
                      1Y
                    </button>{" "}
                  </div> */}
                </div>
              </div>
              <hr className="mb-4" />
              <div id="area-chart" dir="ltr">
                <ReactApexChart
                  options={options1}
                  series={series1}
                  type="area"
                  height={350}
                  className="apex-charts"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} sm="4" className="pe-0">
          <Card>
            <CardBody>
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{t("Users")}</h4>
                <div className="ms-auto">
                  <ul className="nav nav-pills">
                    {/* <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "monthly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("monthly");
                        }}
                        id="one_month"
                      >
                        Month
                      </Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "yearly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("yearly");
                        }}
                        id="one_month"
                      >
                        Year
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              <StackedColumnChart
                periodData={periodData}
                dataColors='["#317d9d", "#317d9d"]'
                // dataColors='["#2fe74a","#2fe74a"]'
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(DepositWithraw);
