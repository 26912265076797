import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Table,
} from "reactstrap";
//import action
import DepositWithraw from "./DepositWithraw";

// Pages Components
import CardUser from "./CardUser";
import ChartUser from "./Chart";
("./dashboard.css");

//i18n
import { withTranslation } from "react-i18next";
import "../../assets/scss/style.css";

const Dashboard = (props) => {
  //meta title
  // document.title = "Dashboard";
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl="12">
              <CardUser
                resultStats=""
                dataColors='["--bs-primary", "--bs-warning"]'
              />
            </Col>

            <Col xl="12">
              <DepositWithraw Chart dataColors='["#317d9d", "#2fe74a"]' />
            </Col>
            <Col xl="12">
              <ChartUser />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
