/* eslint-disable react/no-unknown-property */
import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css";
import { withTranslation } from "react-i18next";
import { apiRoute } from "routes/apiRoutes";
import { alerShow } from "admin/commonFunction";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import {
  getProgrammedSavingsServices,
  blockUnblockUser,
  addProgrammedSavingsServices,
  deleteServiceData,
  updateServiceStatus,
} from "services/userServices";
import { successMsgHandler } from "common/successMsgHandler";

const index = ({ t }) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  // document.title = "Users";
  const language = localStorage.getItem("i18nextLng");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dateFilter, setDateFilter] = useState([]);
  const [editId, setEditId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [kycStatusFilter, setKycStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const [formikValues, setFormikValues] = useState({
    name: "",
    nameSp: "",
    interestRate: "",
    tenure: "",
    tenureUnit: "",
    lockingPeriod: "",
    lockingPeriodUnit: "",
    minDepositAmount: "",
    maxDepositAmount: "",
    earlyWithdrawalPenalty: "",
  });
  const perPage = 10;

  //fetch users
  const fetchData = (
    pagenumber,
    pagelimit,
    search = "",
    exportRequest = "false"
  ) => {
    try {
      setLoaderStatus(true);

      getProgrammedSavingsServices(
        pagenumber,
        pagelimit,
        search,
        exportRequest
      ).then((res) => {
        if (exportRequest == "true") {
          return setLoaderStatus(false);
        }
        setData(res?.data?.response?.items);
        let pageCount = Math.ceil(res?.data?.response.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName);
  };

  const ConfirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteService(id);
      }
    });
  };
  const deleteService = async (id) => {
    try {
      await deleteServiceData(id).then((res) => {
        alerShow("Success", "Service Deleted Successfully", "success");
        fetchData(currentPage, perPage);
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "error");
    }
  };
  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value);
    }, 500);

    setTypingTimeout(timeoutId);
  };

  const handleChangeCheck = async (id, check) => {
    updateServiceStatus({
      status: check ? "TRUE" : "FALSE",
      id,
    })
      .then(function (response) {
        toast.success(t(`${successMsgHandler(response.message)}`));
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.error);
      });
  };

  function convertMonthsToYears(months) {
    let years = Math.floor(months / 12); // Get the number of whole years
    let remainingMonths = months % 12; // Get the remaining months

    if (years === 0) {
      return `${remainingMonths} ${t("months")}`;
    } else if (years === 1 && remainingMonths === 0) {
      return `1 ${t("year")}`;
    } else if (years === 1) {
      return `1 ${t("year")} ${remainingMonths} ${t("months")}`;
    } else if (remainingMonths === 0) {
      return `${years} ${t("years")}`;
    } else {
      return `${years} ${t("years")} ${remainingMonths} ${t("months")}`;
    }
  }

  const handleEdit = (element) => {
    setIsEdit(true);
    setFormikValues({
      name: element.name || "",
      nameSp: element.nameSp || "",
      interestRate: element.interestRate || "",
      tenureUnit: element.tenureIn || "",
      tenure:
        element?.tenureIn == "years"
          ? element.tenure / 12
          : element.tenure || "",
      lockingPeriodUnit: element.lockingPeriodIn || "",
      lockingPeriod:
        element?.lockingPeriodIn == "years"
          ? element.lockingPeriod / 12
          : element?.lockingPeriod || "",
      minDepositAmount: element.minimumDepositAmount || "",
      maxDepositAmount: element.maximumDepositAmount || "",
      earlyWithdrawalPenalty: element.earlyWithdrawalPenalty || "",
    });
    setEditId(element?._id);
    handelShow1();
  };

  const formik = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      nameSp: Yup.string().required(t("Required")),
      interestRate: Yup.string().required(t("Required")),
      tenure: Yup.string().required(t("Required")),
      tenureUnit: Yup.string().required(t("Required")),
      lockingPeriod: Yup.string().required(t("Required")),
      lockingPeriodUnit: Yup.string().required(t("Required")),
      minDepositAmount: Yup.string().required(t("Required")),
      maxDepositAmount: Yup.string().required(t("Required")),
      earlyWithdrawalPenalty: Yup.string().required(t("Required")),
    }),
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = async (values) => {
    // console.log(values, "value");
    const dataValues = {
      tenureIn: values?.tenureUnit,
      lockingPeriodIn: values?.lockingPeriodUnit,
      maximumDepositAmount: values?.maxDepositAmount.toString(),
      minimumDepositAmount: values?.minDepositAmount.toString(),
      earlyWithdrawalPenalty: values?.earlyWithdrawalPenalty.toString(),
      interestRate: values?.interestRate.toString(),
      name: values?.name,
      nameSp: values?.nameSp,
      lockingPeriod:
        values?.lockingPeriodUnit == "months"
          ? values?.lockingPeriod.toString()
          : (values?.lockingPeriod * 12).toString(),
      tenure:
        values?.tenureUnit == "months"
          ? values?.tenure.toString()
          : (values?.tenure * 12).toString(),
    };

    try {
      await (isEdit
        ? updateServiceStatus({ ...dataValues, id: editId })
        : addProgrammedSavingsServices(dataValues)
      ).then((res) => {
        toast.success(t(`${successMsgHandler(res.message)}`));
        handleClose();
        fetchData(currentPage, perPage);
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  useEffect(() => {
    formik.resetForm();
  }, [Add]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className="text-color-gredient">
                          {" "}
                          {t("Programmed Savings Service")}{" "}
                        </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                {t("Search this table")}
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`${t("Search")}...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <button
                            type="button"
                            className="btn all-common-btn w-md"
                            onClick={() =>
                              fetchData(
                                currentPage,
                                perPage,
                                searchName,
                                "true"
                              )
                            }
                          >
                            {t("Export CSV")}
                          </button>
                        </div>
                        <div>
                          <div style={{ marginRight: "0px !important" }}>
                            <button
                              type="button"
                              className="btn all-common-btn"
                              onClick={() => {
                                handelShow1();
                                setIsEdit(false);
                                setFormikValues({
                                  name: "",
                                  nameSp: "",
                                  interestRate: "",
                                  tenure: "",
                                  tenureUnit: "",
                                  lockingPeriod: "",
                                  lockingPeriodUnit: "",
                                  minDepositAmount: "",
                                  maxDepositAmount: "",
                                  earlyWithdrawalPenalty: "",
                                });
                              }}
                            >
                              <i className="bx bx-plus"></i> {t("Add New")}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>{t("Name")}</Th>
                        <Th>{t("Interest Rate")}</Th>
                        <Th>{t("Tenure")}</Th>
                        <Th>{t("Locking Period")}</Th>
                        <Th>{t("Minimum Deposit")}</Th>
                        <Th>{t("Maximum Deposit")}</Th>
                        <Th>{t("Early Withdrawal Penalty")}</Th>
                        <Th>{t("Created At")}</Th>
                        <Th>{t("Status")}</Th>
                        <Th>{t("Action")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="11"> {t("Loading")} ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                {language === "sp"
                                  ? element.nameSp
                                  : element.name}
                              </td>
                              <td>{element.interestRate}%</td>
                              <td>
                                {convertMonthsToYears(element?.tenure)}
                                {/* {element.tenureIn == "months"
                                  ? element?.tenure
                                  : element?.tenure * 12}{" "} */}
                              </td>
                              <td>
                                {" "}
                                {convertMonthsToYears(element?.lockingPeriod)}
                                {/* {element.lockingPeriodIn == "months"
                                  ? element?.lockingPeriod
                                  : element?.lockingPeriod * 12}{" "} */}
                              </td>
                              <td>$ {element?.minimumDepositAmount}</td>
                              <td>$ {element?.maximumDepositAmount}</td>
                              <td>{element?.earlyWithdrawalPenalty}%</td>
                              <td>{dateformat(element.createdAt)}</td>
                              <td>
                                <div className="square-switch mytoggle ">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${element._id}`}
                                    switch="success"
                                    defaultChecked={Boolean(
                                      element.status == "TRUE" ? true : false
                                    )}
                                    value={element._id}
                                    onClick={(e) =>
                                      handleChangeCheck(
                                        element?._id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`square-switch${element._id}`}
                                    data-on-label={t("Active")}
                                    data-off-label={t("Inactive")}
                                  />
                                </div>
                              </td>
                              <td>
                                <Link
                                  className="btn btn-sm all-common-btn"
                                  to="#"
                                  onClick={() => {
                                    handleEdit(element);
                                  }}
                                >
                                  <i className="mdi mdi mdi-square-edit-outline" />
                                </Link>
                                {/* <Link
                                  className="btn btn-sm btn-danger"
                                  to="#"
                                  onClick={() => {
                                    ConfirmDelete(element._id);
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete-outline"
                                    id="deletetooltip"
                                  />
                                </Link> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit
              ? t("Edit Programmed Savings Service")
              : t("Add New Programmed Savings Service")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Name")} ({t("In English")})
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder=""
                    autoFocus="true"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Name")} ({t("In Spanish")})
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nameSp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nameSp}
                    placeholder=""
                    autoFocus="true"
                  />
                  {formik.touched.nameSp && formik.errors.nameSp ? (
                    <div style={{ color: "red" }}>{formik.errors.nameSp}</div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Interest Rate")} ({t("In %")})
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="interestRate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interestRate}
                    placeholder=""
                    autoFocus="true"
                    min={0}
                  />
                  {formik.touched.interestRate && formik.errors.interestRate ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.interestRate}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Early Withdrawal Penalty")} ({t("In %")})
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="earlyWithdrawalPenalty"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.earlyWithdrawalPenalty}
                    placeholder=""
                    min={0}
                    autoFocus="true"
                  />
                  {formik.touched.earlyWithdrawalPenalty &&
                  formik.errors.earlyWithdrawalPenalty ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.earlyWithdrawalPenalty}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label>{t("Tenure Unit")}</label>
                  <select
                    className="form-control"
                    name="tenureUnit"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tenureUnit}
                  >
                    <option value=""> --{t("Select")}-- </option>
                    <option value="months"> {t("Months")} </option>
                    <option value="years"> {t("Years")} </option>
                  </select>
                  {formik.touched.tenureUnit && formik.errors.tenureUnit ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.tenureUnit}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("Tenure")}</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    max={formik.values.tenureUnit == "months" && 12}
                    name="tenure"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tenure}
                    placeholder=""
                    autoFocus="true"
                  />
                  {formik.touched.tenure && formik.errors.tenure ? (
                    <div style={{ color: "red" }}>{formik.errors.tenure}</div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label>{t("Locking Period Unit")}</label>
                  <select
                    className="form-control"
                    name="lockingPeriodUnit"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lockingPeriodUnit}
                  >
                    <option value=""> --{t("Select")}-- </option>
                    <option value="months"> {t("Months")} </option>
                    <option value="years"> {t("Years")} </option>
                  </select>
                  {formik.touched.lockingPeriodUnit &&
                  formik.errors.lockingPeriodUnit ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.lockingPeriodUnit}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("Locking Period")}</Form.Label>
                  <Form.Control
                    type="number"
                    name="lockingPeriod"
                    onChange={formik.handleChange}
                    min={0}
                    max={formik.values.lockingPeriodUnit == "months" && 12}
                    onBlur={formik.handleBlur}
                    value={formik.values.lockingPeriod}
                    placeholder=""
                    autoFocus="true"
                  />
                  {formik.touched.lockingPeriod &&
                  formik.errors.lockingPeriod ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.lockingPeriod}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Minimum Deposit Amount")} ({t("In $")})
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="minDepositAmount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.minDepositAmount}
                    placeholder=""
                    min={0}
                    autoFocus="true"
                  />
                  {formik.touched.minDepositAmount &&
                  formik.errors.minDepositAmount ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.minDepositAmount}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("Maximum Deposit Amount")} ({t("In $")})
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="maxDepositAmount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={formik.values.minDepositAmount}
                    value={formik.values.maxDepositAmount}
                    placeholder=""
                    autoFocus="true"
                  />
                  {formik.touched.maxDepositAmount &&
                  formik.errors.maxDepositAmount ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.maxDepositAmount}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            </Row>

            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="all-common-btn"
              style={{ marginLeft: 10 }}
            >
              {t("Add")}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

index.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(index);
