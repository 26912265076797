import usFlag from "../assets/images/us.jpg";
import spain from "../assets/images/spain.jpg";

const languages = {
  sp: {
    label: "Spanish",
    flag: spain,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
