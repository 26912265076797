import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown";
// import { apiHeader } from "helpers/api_helper";
import { withRouter, Link, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/short-logo-home.png";
import { useHistory } from "react-router";
import "../../assets/css/style.css";
import { adminLogin } from "services/userServices";
import { toastCatchError } from "admin/commonFunction";
import PasswordTypeComp from "components/Common/PasswordTypeComp";
import { errorMsgHandler } from "common/errorMsgHandler";

const Login = ({ t }) => {
  //meta title
  // document.title = "Login";
  const [passwordType, setPasswordType] = useState("password");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Please Enter Your Email")),
      password: Yup.string().required(t("Please Enter Your Password")),
    }),
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const resp = await adminLogin(values);
        if (resp.status == 200) {
          localStorage.setItem("authUser", JSON.stringify(resp?.data));
          // setTimeout(() => {
          // apiHeader();
          history.push("/admin/dashboard");
          // }, 2000);
        }
      } catch (error) {
        toastCatchError(t(`${errorMsgHandler(error)}`));
      } finally {
        setLoader(false);
      }
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/* <i className="bx bx-home h2" /> */}
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ background: "#317D9D" }}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">{t("Welcome Back")} !</h5>
                        <p className="text-white">
                          {t("Sign in to continue to Fintech Blok.")}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="#" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-4 bg-soft"
                        style={{ height: "4.5rem", width: "5.5rem" }}
                      >
                        <span
                          className="avatar-title rounded border"
                          style={{
                            background: "#fff",
                          }}
                        >
                          <img src={logo} height="55" className="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder=""
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="form-label">{t("Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={passwordType}
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />

                        <PasswordTypeComp
                          passwordType={passwordType}
                          setPasswordType={setPasswordType}
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loader}
                          style={{ background: "#7EE387", border: "#7EE387" }}
                        >
                          {loader ? `${t("Loading")} ...` : t("Log In")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {t("Forgot your password?")}
                        </Link>
                      </div>
                    </Form>
                    <div className="text-center">
                      {" "}
                      <strong>Language: </strong>
                      <LanguageDropdown />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white d-none">
                  © {new Date().getFullYear()} .{" "}
                  {t("Design & Developed by Block Tech Brew")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));
Login.propTypes = {
  history: PropTypes.object,
};
