/* eslint-disable react/no-unknown-property */
import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Badge } from "react-bootstrap";
import { StandardPickerDateFormat } from "common/utility";
import "../../assets/scss/style.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { fireToast } from "common/toast";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import { CInputGroup, CInputGroupText } from "@coreui/react";
import "flatpickr/dist/themes/material_blue.css";
import Schedule from "../../assets/images/Schedule.svg";
import { FILES_URL } from "helpers/api_helper";
import { withTranslation } from "react-i18next";
import { dateformat } from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { getAllKycRequests, kycStatusUpdate } from "services/userServices";
import { successMsgHandler } from "common/successMsgHandler";
import { toast } from "react-toastify";

const KycUsers = ({ t }) => {
  // document.title = "Users";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listingData, setData] = useState([]);
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [dateFilter, setDateFilter] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [kycStatusFilter, setKycStatusFilter] = useState("");
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = (
    pagenumber = 1,
    pagelimit = 10,
    search = "",
    kycStatus = "",
    dateFilter = []
  ) => {
    try {
      setLoaderStatus(true);
      const [fromDate, toDate] = dateFilter || [];
      getAllKycRequests(
        pagenumber,
        pagelimit,
        search,
        kycStatus,
        fromDate || "",
        toDate || ""
      ).then((res) => {
        setData(res.data.items);
        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName, kycStatusFilter, dateFilter);
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value, kycStatusFilter, dateFilter);
    }, 500);

    setTypingTimeout(timeoutId);
  };

  // const handleKYCstatus = async (status, id, userId) => {
  //   try {
  //     Swal.fire({
  //       title: t("Are you sure?"),
  //       text: t("You won't be able to revert this!"),
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: t("Yes, do it!"),
  //       cancelButtonText: t("Cancel"),
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         let response = await kycStatusUpdate({
  //           status: status,
  //           id: id,
  //           rejectReason: "",
  //           clientId: userId,
  //         });
  //         if (response.status == 200) {
  //           fireToast(
  //             "success",
  //             status == "APPROVED" ? "KYC Approved!" : "KYC Rejected!"
  //           );
  //           fetchData();
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     fireToast("error", error.message, "Error!");
  //   }
  // };

  const handleKYCstatus = async (status, id, clientId) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await kycStatusUpdate({
            status: status,
            id: id,
            rejectReason: "",
            clientId: clientId,
          });
          if (response.status == 200) {
            toast.success(t(`${successMsgHandler(response.message)}`));
            setKycStatusFilter("");
            fetchData();
          }
        }
      });
    } catch (error) {
      fireToast("error", error.message, "Error!");
    }
  };
  const handleKYCstatusReject = async (status, id, clientId) => {
    try {
      Swal.fire({
        title: t("Are you sure?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, do it!"),
        cancelButtonText: t("Cancel"),
        input: "textarea",
        inputPlaceholder: `${t("Reason for rejection")}...`,
        inputAttributes: {
          autocapitalize: "off",
        },
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        console.log(result, "<<-- -result");
        if (result.isConfirmed) {
          let response = await kycStatusUpdate({
            status: status,
            id: id,
            rejectReason: result?.value,
            clientId: clientId,
          });
          if (response.status == 200) {
            toast.success(t(`${successMsgHandler(response.message)}`));
            setKycStatusFilter("");
            fetchData();
          }
        }
      });
    } catch (error) {
      fireToast("error", error.message, "Error!");
    }
  };

  const handleKycStatusFilter = (e) => {
    setKycStatusFilter(e.target.value);
    if (e.target.value != "") {
      fetchData(1, perPage, searchName, e.target.value, dateFilter);
    } else {
      fetchData(1, perPage, searchName, "", dateFilter);
    }
  };

  const dateFilterChange = (e, picker) => {
    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ];
    setDateFilter(filter);
    fetchData(1, perPage, searchName, kycStatusFilter, filter);
  };

  const inputRef = useRef(null);

  return (
    <React.Fragment>
      <div>
        <Row className="d-flex align-items-center mb-3">
          <Col lg="4">
            <h5 className=" text-color-gredient"> {t("KYC Requests")} </h5>
          </Col>
          <Col lg="8" className="d-flex align-items-center justify-content-end">
            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label mb-0">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    id="search-bar-0"
                    type="search"
                    className="form-control"
                    placeholder={`Search...`}
                    style={{ borderRadius: "4px" }}
                    onChange={handleSearchClick}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div> */}

            <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
              <select
                className="form-control select2 mb-xxl-0"
                style={{ width: "165px" }}
                value={kycStatusFilter}
                onChange={handleKycStatusFilter}
              >
                <option value="">{t("Select KYC")}</option>
                <option value="PENDING">{t("Pending")}</option>
                {/* <option value="INPROGRESS">In-progress</option> */}
                <option value="APPROVED">{t("Approved")}</option>
                <option value="REJECTED">{t("Rejected")}</option>
              </select>
            </div>
            <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
              <label htmlFor="search-bar-0" className="w-100 m-0">
                <span id="search-bar-0-label" className="sr-only">
                  {t("Filter by date")}
                </span>
                <CInputGroup className="">
                  <DateRangePicker
                    // initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                    onApply={dateFilterChange}
                    // onCancel={}
                    onShow={() => setCalendarIsShowing(true)}
                    onHide={() => setCalendarIsShowing(false)}
                  >
                    <input
                      ref={inputRef}
                      placeholder={t("Filter by Date")}
                      className="form-control"
                      readOnly={true}
                      value={
                        dateFilter.length
                          ? `${StandardPickerDateFormat(
                              dateFilter[0]
                            )} - ${StandardPickerDateFormat(dateFilter[1])}`
                          : ""
                      }
                    />
                  </DateRangePicker>
                  <CInputGroupText>
                    <img
                      onClick={() => {
                        if (!calendarIsShowing && dateFilter.length) {
                          setDateFilter([]);
                          fetchData(1, perPage, searchName, statusFilter, []);
                        }
                      }}
                      src={
                        calendarIsShowing
                          ? calendarremovelines
                          : dateFilter.length
                          ? calendarremovelines
                          : Schedule
                      }
                      alt=""
                      width={20}
                    />
                  </CInputGroupText>
                </CInputGroup>
              </label>
            </div>
          </Col>
        </Row>
      </div>

      <Table
        id="tech-companies-1"
        className="table table-striped table-bordered"
      >
        <Thead>
          <Tr>
            <Th>Sr. No.</Th>
            <Th>{t("Name")}</Th>
            <Th>{t("Email")}</Th>
            <Th>{t("Phone Number")}</Th>
            <Th>{t("Created At")}</Th>
            <Th>{t("Status")}</Th>
            <Th> {t("View Document")}</Th>
            <Th> {t("Action")} </Th>
          </Tr>
        </Thead>
        <Tbody>
          {loaderStatus ? (
            <Tr>
              <Td colSpan="8"> {t("Loading")} ... </Td>
            </Tr>
          ) : listingData.length != 0 ? (
            listingData.map((element, index) => {
              currentPage > 1
                ? (incermentInd = (currentPage - 1) * perPage + 1)
                : 0;
              return (
                <tr key={index}>
                  <td>
                    <span className="co-name"> {incermentInd + index} </span>
                  </td>
                  <td>{element?.firstName + " " + element?.lastName}</td>

                  <td>{element.email}</td>
                  <td>{element.mobileNo}</td>
                  <td>{dateformat(element.createdAt)}</td>
                  <td>
                    <Badge
                      bg={
                        element.status == "PENDING"
                          ? "warning"
                          : element.status == "INPROGRESS"
                          ? "info"
                          : element.status == "COMPLETED"
                          ? "success"
                          : element.status == "REJECTED"
                          ? "danger"
                          : "info"
                      }
                      pill="true"
                      className="p-2"
                    >
                      {t(`${element?.status}`)}
                    </Badge>
                  </td>
                  <td>
                    <ul className="list-unstyled hstack gap-1 mb-0">
                      <li>
                        <button
                          className="btn btn-sm all-common-btn"
                          onClick={() =>
                            window.open(FILES_URL + element?.documentId)
                          }
                        >
                          {t("View")}
                        </button>
                      </li>
                    </ul>
                  </td>
                  <td>
                    {element?.status == "PENDING" ? (
                      <div
                        className="btn-group btn-group-example mb-3"
                        role="group"
                      >
                        <button
                          type="button"
                          className="btn btn-primary w-xs px-4"
                          onClick={() =>
                            handleKYCstatus(
                              "APPROVED",
                              element._id,
                              element?.userId
                            )
                          }
                        >
                          <i className="mdi mdi-thumb-up"></i> {t("Approve")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-xs px-4"
                          onClick={() =>
                            handleKYCstatusReject(
                              "REJECTED",
                              element._id,
                              element?.userId
                            )
                          }
                        >
                          <i className="mdi mdi-thumb-down"></i> {t("Reject")}
                        </button>
                      </div>
                    ) : (
                      <Badge
                        bg={
                          element.status == "PENDING"
                            ? "warning"
                            : element.status == "INPROGRESS"
                            ? "info"
                            : element.status == "COMPLETED"
                            ? "success"
                            : element.status == "REJECTED"
                            ? "danger"
                            : "info"
                        }
                        pill="true"
                        className="p-2"
                      >
                        {t(element?.status)}
                      </Badge>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8}>No Records Found</td>
            </tr>
          )}
        </Tbody>
      </Table>

      <Row className="mt-2">
        <Col md={9}></Col>
        <Col md={3}>
          <Pagination
            {...bootstrap5PaginationPreset}
            current={currentPage}
            total={totalCount}
            onPageChange={(page) => handlePageClick(page)}
            className="pagination justify-content-end"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

KycUsers.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(KycUsers);
