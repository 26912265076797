import appendData from "common/utility";
import {
  del,
  get,
  getWithExportFile,
  post,
  postAsForm,
  put,
} from "helpers/api_helper";

export const addNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const getProgrammedSavingsServices = (
  pageNumber,
  pageSize,
  filter = "",
  exportRequest = "false"
) => {
  return getWithExportFile(
    `/service/getAllServices?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&exportCSV=${exportRequest}`
  );
};
export const addProgrammedSavingsServices = (data) => {
  return post("/service/saveServices", data);
};
export const addNewPlans = (data) => {
  return post("/plan/createPlan", data);
};
export const updateServiceStatus = (data) => {
  return put("/service/updateService", data);
};
export const deleteClientUser = (data) => {
  return put("/admin/deleteUser", data);
};

export const updateNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const updateNewPlan = (data) => {
  return post("/plan/createPlan", data);
};

export const getFaqDetail = () => {
  return get("/admin/getFaq");
};
export const getPlanDetail = () => {
  return get("/plan/getAllPlans");
};

export const deleteFaqData = (data) => {
  return del("/admin/deleteFaq", data);
};
export const deleteServiceData = (data) => {
  return del("", data);
};
export const deletePlanData = (data) => {
  return del("/plan/deletePlan", data);
};

export const adminLogin = (data) => {
  return post("/admin/login", data);
};

export const reSendOtp = (data) => {
  return post("/admin/resendotp", data);
};

export const forgetPassword = (data) => {
  return post("/admin/forgotPassword", data);
};

export const verifyOtp = (data) => {
  return put("/admin/verifyOtp", data);
};

export const resetPassword = (new_password, token) => {
  return post(
    "/admin/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

export const getUserProfile = () => {
  return get("/admin/me");
};

export const getAllUser = (
  pageNumber,
  pageSize,
  filter,
  fromDate,
  toDate,
  isBlocked = "",
  kycStatus = "",
  exportRequest = ""
) => {
  return getWithExportFile(
    `/admin/allUser?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&isBlocked=${isBlocked}&kycStatus=${kycStatus}&exportCSV=${exportRequest}`
  );
};

export const getAllQueries = (
  pageNumber,
  pageSize,
  filter = "",
  filter2 = "",
  fromDate = "",
  toDate = ""
) => {
  return getWithExportFile(
    `/admin/getAllClientQueries?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&filter2=${filter2}&fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const getAllKycRequests = (
  pageNumber,
  pageSize,
  filter = "",
  filter2 = "",
  fromDate = "",
  toDate = ""
) => {
  return getWithExportFile(
    `/admin/kycUserslist?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter2}&startDate=${fromDate}&endDate=${toDate}`
  );
};
export const getAllTransactions = (pageNumber, pageSize) => {
  return getWithExportFile(
    `/transaction/getAllTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const updateProfile = (data) => {
  return post("/admin/update", data);
};
export const updateUserDetail = (data) => {
  return post("/admin/updateUserDetail", data);
};
export const blockUnblockUser = (data) => {
  return put("/admin/blockUser", data);
};
export const queryStatusChange = (data) => {
  return put("/admin/updateContactUsStatus", data);
};

export const airdropTransactionSave = (data) => {
  return post("/transaction/saveTransaction", data);
};
export const updateProfileImage = (data) => {
  const formData = appendData(data);
  return postAsForm("/admin/uploadImage", formData);
};
export const getKycPdf = () => {
  return get("client/getKycDocument");
};
export const uploadKycPdf = (data) => {
  const formData = appendData(data);
  return postAsForm("/admin/saveKycDocument", formData);
};

export const changePassword = (data) => {
  return post("/admin/changePassword", data);
};

export const updateWithdrawStatus = (data) => {
  return put("/admin/updateWithdrawStatus", data);
};

export const csmUpdateFlow = (data) => {
  return post("/admin/csm/update", data);
};
export const getCsmFlow = (type) => {
  return get("/admin/csm/detail?type=" + type);
};
export const dashboardStats = () => {
  return get("Dashboard/getDashboardData");
};
export const getUserGraphData = () => {
  return get("Dashboard/getuserOnBoardData");
};
export const transactionGraph = (type) => {
  return get("admin/transactionGraph");
};
export const getWithdrawGraphData = () => {
  return get("Dashboard/getMoneyWithdrawData");
};
export const getDepositGraphData = () => {
  return get("Dashboard/getMoneyDepositData");
};
export const userStorageInfo = (userId) => {
  return get("admin/userStorage?userId=" + userId);
};
export const getServiceDetail = (id) => {
  return get("client/service/detail?serviceId=" + id);
};
export const userTransactionsInfo = (userId) => {
  return get("admin/userTransactions?userId=" + userId);
};

export const userKycInfo = (userId) => {
  return get("client/kyc/kycInfo?userId=" + userId);
};
export const kycStatusUpdate = (data) => {
  return put("/admin/updateKycStatus", data);
};

export const savingServiceDetailOneServiceOfUser = (userId, serviceId) => {
  return get(
    `/service/getInvestedSchemeDetailsByAdmin?id=${userId}&serviceId=${serviceId}`
  );
};

export const getNotifications = () => {
  return get("admin/getAdminNotification");
};
