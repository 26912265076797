import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";
import PasswordTypeComp from "components/Common/PasswordTypeComp";
import { alerShow } from "../commonFunction";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import "../../assets/scss/style.css";
import {
  changePassword,
  getUserProfile,
  updateProfile,
} from "services/userServices";
import { successMsgHandler } from "common/successMsgHandler";

const Dashboard = ({ t }) => {
  //meta title
  // document.title = "Change Password";
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [details, setDetails] = useState({
    phone_number: "",
    email: "",
  });
  const changeProfile = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  // Form validation
  const profileValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: details.email,
      phone_number: details.phone_number,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Support Email"),
      phone_number: Yup.string().required("Please Enter Phone Number"),
    }),
    onSubmit: (values, actions) => {
      let collectionData = {
        phoneNumber: values.phone_number,
        email: values.email,
        name: "Admin",
      };
      updateProfile(collectionData)
        .then(function (response) {
          alerShow("success", response.message, "Success");
          actions.resetForm(values);
        })
        .catch(function (error) {
          alerShow("error", error.response.data.error, "Error");
        });
    },
  });

  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });
  const changeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: formValues.oldPassword,
      password: formValues.password,
      confirm_password: formValues.confirm_password,
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(t("Required")),
      password: Yup.string().required(t("Required")),
      confirm_password: Yup.string()
        .required(t("Required"))
        .oneOf([Yup.ref("password"), null], t("Passwords must match")),
    }),
    onSubmit: (values, actions) => {
      let collectionData = {
        oldPassword: values.oldPassword,
        newPassword: values.password,
      };
      changePassword(collectionData)
        .then(function (response) {
          toast.success(t(`${successMsgHandler(response.message)}`));
          actions.resetForm(values);
          setTimeout(() => {
            localStorage.removeItem("authUser");
            history.push("/login");
          }, 2000);
        })
        .catch(function (error) {
          alerShow("Error", error.response.data.error, "error");
        });
    },
  });

  const [admin, setAdmin] = useState(null);
  const getAdminProfile = async () => {
    try {
      let detail = await getUserProfile();
      if (detail.status == 200) {
        setDetails({
          email: detail.data.supportEmail,
          phone_number: detail.data.phoneNumber,
        });
      }
    } catch (error) {
      console.log(error, "error profile api");
    }
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Profile")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">
                    {" "}
                    {t("Change Password")}{" "}
                  </CardTitle>

                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3 position-relative">
                          <Label htmlFor="formrow-password-Input">
                            {t("Old Password")}
                          </Label>
                          <Input
                            type={passwordType}
                            name="oldPassword"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder=""
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                          />
                          <PasswordTypeComp
                            passwordType={passwordType}
                            setPasswordType={setPasswordType}
                          />
                          {validation.touched.oldPassword &&
                          validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.oldPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            {t("New Password")}
                          </Label>
                          <Input
                            type={passwordType1}
                            name="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder=""
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                          />
                          <PasswordTypeComp
                            passwordType={passwordType1}
                            setPasswordType={setPasswordType1}
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            {t("Confirm Password")}
                          </Label>
                          <Input
                            type={passwordType2}
                            name="confirm_password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder=""
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                          />
                          <PasswordTypeComp
                            passwordType={passwordType2}
                            setPasswordType={setPasswordType2}
                          />
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button
                        type="submit"
                        className="btn all-common-btn w-md mt-3"
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
