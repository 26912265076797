export const successMsgHandler = (responseMsg) => {
  return responseMsg === "Delete Successfully"
    ? "Deleted Successfully"
    : responseMsg === "Blocked by Admin"
    ? "User Blocked Successfully"
    : responseMsg === "Unblocked by Admin"
    ? "User Unblocked Successfully"
    : responseMsg === "Updated Successfully"
    ? "Updated Successfully"
    : responseMsg === "Saved Successfully"
    ? "Saved Successfully"
    : responseMsg === "Password changed successfully"
    ? "Password changed successfully"
    : responseMsg === "Verified Successfully!"
    ? "Verified Successfully!"
    : responseMsg === "Password Reset Successfully"
    ? "Password Reset Successfully"
    : responseMsg;
};
