import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { CommonContext } from "context/CommonContext";
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";
import moment from "moment";

const NotificationDropdown = (props) => {
  const { notificationCount, notifications, getAllNotifications } =
    useContext(CommonContext);

  const [menu, setMenu] = useState(false);

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        onClick={() => getAllNotifications()}
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={
              notificationCount > 0 ? "bx bx-bell bx-tada" : "bx bx-bell"
            }
          />
          <span className="badge bg-danger rounded-pill">
            {notificationCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications?.length > 0 ? (
              notifications?.map((item, index) => (
                <Link
                  to="/"
                  className="text-reset notification-item"
                  key={index}
                >
                  <div
                    className="d-flex mb-2"
                    style={{ background: item?.view !== true && "#77c4ff2b" }}
                  >
                    <div className="avatar-xs me-3">
                      <span
                        className="avatar-title bg-primary rounded-circle"
                        style={{ height: "25px", width: "25px" }}
                      >
                        <i className="bx bx-bell" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{item?.notifications}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{item?.message}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {moment(item?.createdAt).format("lll")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className={`notify-item p-4`} style={{ width: "400px" }}>
                {"No Notification"}
              </div>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
