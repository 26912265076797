import React from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";

const Footer = ({ t }) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            {/* <Col md={6}>{new Date().getFullYear()} © FINTEX.</Col> */}
            <Col md={12}>
              <div className="text-sm-end d-none d-sm-block">
                {t("Design & Developed by")}{" "}
                <a
                  style={{ color: "#388db2" }}
                  href="https://blocktechbrew.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Block Tech Brew
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default withTranslation()(Footer);
