import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import ForgotPassOtpModal from "./ForgotPassOtpModal";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { toastCatchError } from "admin/commonFunction";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { forgetPassword, verifyOtp } from "services/userServices";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/short-logo-home.png";
import { successMsgHandler } from "common/successMsgHandler";
import { errorMsgHandler } from "common/errorMsgHandler";

const ForgetPasswordPage = ({ t }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Please Enter Your Email")),
    }),
    onSubmit: async (values) => {
      // setShow(true);
      setEmail(values?.email);
      try {
        setLoader(true);
        const resp = await forgetPassword(values);
        if (resp.status == 200) {
          console.log(resp, "success login ");
          setShow(true);
          setLoader(false);
        }
      } catch (error) {
        console.log(error, "errrr");
        toastCatchError(t(`${errorMsgHandler(error)}`));
        setLoader(false);
      } finally {
        setLoader(false);
      }
    },
  });

  const formik = useFormik({
    initialValues: { otp: "", email: email },
    enableReinitialize: true,
    validationSchema: Yup.object({
      otp: Yup.string().required(t("OTP is required")),
    }),
    onSubmit: async (values) => {
      try {
        // setLoader(true);
        const resp = await verifyOtp(values);
        if (resp.status == 200) {
          setShow(false);
          toast.success(t(`${successMsgHandler(resp.message)}`));
          localStorage.setItem("token-info", resp?.data?.token);
          history.push("/reset-password");
        }
      } catch (error) {
        toastCatchError(t(`${errorMsgHandler(error)}`));
      } finally {
        // setLoader(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/* <i className="bx bx-home h2" /> */}
        </Link>
      </div>
      <div className="account-pages  my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ background: "#317D9D" }}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">{t("Welcome Back")} !</h5>
                        {/* <p className="text-white">
                          Sign in to continue to Fintech Blok.
                        </p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="#" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-4 bg-soft"
                        style={{ height: "4.5rem", width: "5.5rem" }}
                      >
                        <span
                          className="avatar-title rounded border"
                          style={{
                            background: "#fff",
                          }}
                        >
                          <img src={logo} height="55" className="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder=""
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-4 mt-4">
                        <Col className="text-center">
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              style={{
                                background: "#7EE387",
                                border: "#7EE387",
                              }}
                            >
                              {loader ? `${t("Loading")} ...` : t("Submit")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-0 text-center">
                        <p>
                          {t("Go back to")}{" "}
                          <Link
                            to="login"
                            className="font-weight-medium text-primary"
                          >
                            {t("Login Page")}
                          </Link>{" "}
                        </p>
                      </div>
                      <div className="mt-3 text-center">
                        <p>
                          © {new Date().getFullYear()} .{" "}
                          {t("Design & Developed by Block Tech Brew")}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ForgotPassOtpModal
        show={show}
        handleClose={handleClose}
        formik={formik}
      />
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};
export default withRouter(withTranslation()(ForgetPasswordPage));
