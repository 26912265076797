import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { getServiceDetail } from "services/userServices";
import PropTypes from "prop-types";
import { dateformat } from "admin/commonFunction";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const ServiceDetail = ({ t }) => {
  const location = useLocation();
  const { userDetail } = location.state;

  const [data, setData] = useState([]);
  const [planCurrentPage, setPlanCurrentPage] = useState(1);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  const handlePlanPageClick = (page) => {
    setPlanCurrentPage(page);
    fetchPurchasedPlans(userDetail?._id, page, perPage);
  };

  const fetchDetails = (userId) => {
    try {
      setLoaderStatus(true);
      getServiceDetail(userId).then((res) => {
        setData(res?.data);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDetails(userDetail?._id);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Users")}
            titlePath="/admin/users"
            breadcrumbItem={t("Service Detail")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Col sm="3">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-warning bg-soft text-warning">
                            <i className="bx bx-calendar h3 mb-0" />
                          </span>
                        </div>

                        <div className="flex-grow-1">
                          <p className="text-muted mb-2"> {t("Start Date")} </p>
                          <h5 className="mb-0">
                            {dateformat(data?.service?.startDate)}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-danger bg-soft text-danger">
                            <i className="bx bx-calendar h3 mb-0" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">End Date</p>
                          <h5 className="mb-0">
                            {dateformat(data?.service?.endDate)}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-success bg-soft text-success">
                            <i className="bx bx-dollar h4 mb-0" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Installment Amount</p>
                          <h5 className="mb-0">${data?.service?.amount}</h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="mini-stats-wid">
                    <CardBody className="ps-3 pe-1">
                      <div className="d-flex">
                        <div className="avatar-sm mx-auto me-3">
                          <span className="avatar-title rounded-circle bg-info bg-soft text-info">
                            <i className="bx bx-dollar h4 mb-0" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">
                            Total Investment Amount
                          </p>
                          <h5 className="mb-0">
                            ${data?.totalInvestmentAmount}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">{t("Transactions")} </h4>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No.</th>
                          <th scope="col">{t("Amount")}</th>
                          <th scope="col">{t("Date")}</th>
                          <th scope="col">{t("Method")}</th>
                          <th scope="col">{t("Type")}</th>
                          <th scope="col">{t("Card Number")}</th>
                          <th scope="col">{t("Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loaderStatus ? (
                          <tr>
                            <td colSpan="7"> {t("Loading")} ... </td>
                          </tr>
                        ) : data?.transactions?.length != 0 ? (
                          data?.transactions?.map((item, index) => {
                            planCurrentPage > 1
                              ? (incermentInd =
                                  (planCurrentPage - 1) * perPage + 1)
                              : 0;
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="co-name">
                                    {" "}
                                    {incermentInd + index}{" "}
                                  </span>
                                </td>
                                <td> ${item?.amount?.toFixed(2)}</td>
                                <td>{moment(item?.updatedAt).format("lll")}</td>
                                <td> {item?.paymentMedium}</td>
                                <td>
                                  {item?.transactionType == "Buy_Service"
                                    ? "Debit"
                                    : item?.transactionType ==
                                      "Complete_Service"
                                    ? "Credit"
                                    : item?.transactionType == "Sell_Service"
                                    ? "Terminated"
                                    : "-"}
                                </td>
                                <td>
                                  {item?.cardnumber ? item?.cardnumber : "-"}
                                </td>

                                <td>
                                  <span
                                    className={`me-1 p-2 badge bg-${
                                      item?.status == "PENDING"
                                        ? "success"
                                        : item?.status == "FAILED"
                                        ? "danger"
                                        : "info"
                                    }`}
                                  >
                                    {item?.status === "PENDING"
                                      ? t("COMPLETED")
                                      : item?.status}
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>{t("No Records Found")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ServiceDetail.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ServiceDetail));
