import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/scss/style.css";
import { dashboardStats } from "services/userServices";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

function CardUser({ t }) {
  const [stats, setStats] = useState();
  const getDashboardInfo = async () => {
    try {
      let response = await dashboardStats();
      if (response.status == 200) {
        setStats(response.data);
      }
    } catch (error) {
      // toastCatchError(error)
      getDashboardInfo();
    }
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="mb-2">
            <CardBody className="border">
              <Row className="">
                <Col lg="12" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col>
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                {t("Total Users")}
                              </p>
                              <h5 className="mb-0">
                                {" "}
                                {stats?.totalUsers ? stats?.totalUsers : 0}{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                {t("Total Verified Users")}
                              </p>
                              <h5 className="mb-0">
                                {" "}
                                {stats?.totalVerifiedUsers
                                  ? stats?.totalVerifiedUsers
                                  : 0}{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col>
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                {t("Pending KYCs")}
                              </p>
                              <h5 className="mb-0">
                                {stats?.pendingKycUser
                                  ? stats?.pendingKycUser
                                  : 0}{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                {t("Total Deposit")}
                              </p>
                              <h5 className="mb-0">
                                {stats?.totalBalance ? stats?.totalBalance : 0}{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                {t("Today's Tranactions")}
                              </p>
                              <h5 className="mb-0">
                                {stats?.todayTransactions
                                  ? stats?.todayTransactions
                                  : 0}{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

CardUser.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(CardUser));
