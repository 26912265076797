import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import OTPInput from "react-otp-input";

const ForgotPassOtpModal = ({ show, handleClose, formik, t }) => {
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      // backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Verify OTP")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group
            className="mb-3 forgot-otp-modal"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>{t("Enter OTP")}</Form.Label>
            <div className="otp-input">
              <OTPInput
                name="otp"
                value={formik.values.otp}
                onChange={(enterOtp) => {
                  formik.setFieldValue("otp", enterOtp);
                  if (enterOtp.length === 6) {
                    formik.handleSubmit();
                  }
                }}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {formik.touched.otp && formik.errors.otp ? (
              <div style={{ color: "red" }}>{formik.errors.otp}</div>
            ) : null}
          </Form.Group>

          <div className="text-center mt-3">
            {" "}
            <Button
              onClick={() => formik.handleSubmit()}
              type="submit"
              variant="primary"
              className="all-common-btn"
            >
              {t("Verify")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default withRouter(withTranslation()(ForgotPassOtpModal));
