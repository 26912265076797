/* eslint-disable react/no-unknown-property */
import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Badge } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { CInputGroup, CInputGroupText } from "@coreui/react";
import Schedule from "../../assets/images/Schedule.svg";
import { StandardPickerDateFormat } from "common/utility";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import avatar from "../../assets/images/users/dummy.png";
import "flatpickr/dist/themes/material_blue.css";
import { withTranslation } from "react-i18next";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { dateformat } from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import {
  getAllUser,
  blockUnblockUser,
  deleteClientUser,
} from "services/userServices";
import { FILES_URL } from "helpers/api_helper";
import { successMsgHandler } from "common/successMsgHandler";

const Users = ({ t }) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  // document.title = "Users";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dateFilter, setDateFilter] = useState([]);
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [kycStatusFilter, setKycStatusFilter] = useState("");
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = (
    pagenumber,
    pagelimit,
    search = "",
    dateFilter = [],
    status = "",
    kycStatus = "",
    exportRequest = "false"
  ) => {
    try {
      setLoaderStatus(true);
      const [fromDate, toDate] = dateFilter || [];
      // axios
      //   .get(
      //     apiRoute.getUserListing +
      //       "?page=" +
      //       pagenumber +
      //       "&limit=" +
      //       pagelimit +
      //       "&search=" +
      //       search +
      //       "&userStatus=" +
      //       status
      //   )

      getAllUser(
        pagenumber,
        pagelimit,
        search,
        fromDate || "",
        toDate || "",
        status,
        kycStatus,
        exportRequest
      ).then((res) => {
        if (exportRequest == "true") {
          return setLoaderStatus(false);
        }
        setData(res.data.items);

        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(
      page,
      perPage,
      searchName,
      dateFilter,
      statusFilter,
      kycStatusFilter
    );
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(
        1,
        perPage,
        e.target.value,
        dateFilter,
        statusFilter,
        kycStatusFilter
      );
    }, 500);

    setTypingTimeout(timeoutId);

    // setSearchName(event.target.value)
    // setCurrentPage(1)
    // fetchData(1, perPage, event.target.value, statusFilter)
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    if (e.target.value != "") {
      fetchData(
        1,
        perPage,
        searchName,
        dateFilter,
        e.target.value,
        kycStatusFilter
      );
    } else {
      fetchData(1, perPage, searchName, dateFilter, "", kycStatusFilter);
    }
  };

  const handleKycStatusFilter = (e) => {
    setKycStatusFilter(e.target.value);
    if (e.target.value != "") {
      fetchData(
        1,
        perPage,
        searchName,
        dateFilter,
        statusFilter,
        e.target.value
      );
    } else {
      fetchData(1, perPage, searchName, dateFilter, statusFilter, "");
    }
  };

  const dateFilterChange = (e, picker) => {
    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ];
    setDateFilter(filter);
    fetchData(1, perPage, searchName, filter, statusFilter, kycStatusFilter);
  };

  const deleteUser = (userId) => {
    deleteClientUser({
      id: userId,
      value: true,
    })
      .then(function (response) {
        toast.success(t(`${successMsgHandler(response.message)}`));
        fetchData();
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
      });
  };

  //delete Job
  const ConfirmDeleteUser = (userId) => {
    // let URL = BASE_URL + "user/delete/" + userId
    Swal.fire({
      title: t("Are you sure?"),
      text: t("you want to delete this user!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const handleChangeCheck = async (id, check) => {
    blockUnblockUser({
      isBlocked: check,
      id,
    })
      .then(function (response) {
        toast.success(t(`${successMsgHandler(response.message)}`));
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
      });
  };

  const inputRef = useRef(null);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient"> {t("Users")} </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`${t("Search")}...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <label htmlFor="search-bar-0" className="w-100 m-0">
                            <span id="search-bar-0-label" className="sr-only">
                              Filter by date
                            </span>
                            <CInputGroup className="">
                              <DateRangePicker
                                // initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                                onApply={dateFilterChange}
                                // onCancel={}
                                onShow={() => setCalendarIsShowing(true)}
                                onHide={() => setCalendarIsShowing(false)}
                              >
                                <input
                                  ref={inputRef}
                                  placeholder={t("Filter by Date")}
                                  className="form-control"
                                  readOnly={true}
                                  value={
                                    dateFilter.length
                                      ? `${StandardPickerDateFormat(
                                          dateFilter[0]
                                        )} - ${StandardPickerDateFormat(
                                          dateFilter[1]
                                        )}`
                                      : ""
                                  }
                                />
                              </DateRangePicker>
                              <CInputGroupText>
                                <img
                                  onClick={() => {
                                    if (
                                      !calendarIsShowing &&
                                      dateFilter.length
                                    ) {
                                      setDateFilter([]);
                                      fetchData(
                                        1,
                                        perPage,
                                        searchName,
                                        [],
                                        statusFilter,
                                        ""
                                      );
                                    }
                                  }}
                                  src={
                                    calendarIsShowing
                                      ? calendarremovelines
                                      : dateFilter.length
                                      ? calendarremovelines
                                      : Schedule
                                  }
                                  alt=""
                                  width={20}
                                />
                              </CInputGroupText>
                            </CInputGroup>
                          </label>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <button
                            type="button"
                            className="btn all-common-btn w-md"
                            onClick={() =>
                              fetchData(
                                currentPage,
                                perPage,
                                searchName,
                                dateFilter,
                                statusFilter,
                                kycStatusFilter,
                                "true"
                              )
                            }
                          >
                            {t("Export CSV")}
                          </button>
                        </div>
                        {/* <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleStatusFilter}
                          >
                            <option value="">All Users</option>
                            <option value="1">Blocked</option>
                            <option value="0">Unblocked</option>
                          </select>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleKycStatusFilter}
                          >
                            <option value="">Select KYC</option>
                            <option value="PENDING">Pending</option>
                            <option value="INPROGRESS">In-progress</option>
                            <option value="COMPLETED">Completed</option>
                            <option value="REJECTED">Rejected</option>
                          </select>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>{t("User")}</Th>
                        <Th>{t("Phone Number")}</Th>
                        <Th>{t("KYC Status")}</Th>
                        <Th>{t("Status")}</Th>
                        <Th>{t("Created At")}</Th>
                        <Th>{t("View")}</Th>
                        <Th>{t("Action")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="8"> {t("Loading")} ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="me-1">
                                    <img
                                      src={
                                        element?.image
                                          ? FILES_URL + element?.image
                                          : avatar
                                      }
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    />
                                  </div>

                                  <div className="text-muted">
                                    <h5 className="mb-0">
                                      {element?.firstName +
                                        " " +
                                        element?.lastName}
                                    </h5>
                                    <span>{element.email}</span>
                                  </div>
                                </div>
                              </td>

                              <td>{element.mobileNo}</td>
                              <td>
                                <Badge
                                  color={
                                    element.isKYCConfirmed == "PENDING"
                                      ? "warning"
                                      : element.isKYCConfirmed == "APPROVED"
                                      ? "success"
                                      : element?.isKYCConfirmed == "REJECTED"
                                      ? "danger"
                                      : "info"
                                  }
                                  className="me-1 p-1"
                                >
                                  {t(`${element.isKYCConfirmed}`)}
                                </Badge>
                              </td>

                              <td>
                                <div className="square-switch mytoggle">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${element._id}`}
                                    switch="success"
                                    defaultChecked={Boolean(element.isBlocked)}
                                    value={element._id}
                                    onClick={(e) =>
                                      handleChangeCheck(
                                        element?._id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`square-switch${element._id}`}
                                    data-on-label={t("Block")}
                                    data-off-label={t("Unblock")}
                                  />
                                </div>
                              </td>
                              <td>{dateformat(element.createdAt)}</td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to={{
                                        pathname: `/admin/users/detail`,
                                        state: { userDetail: element },
                                      }}
                                      className="btn btn-info"
                                    >
                                      <i className="mdi mdi-eye" />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    ConfirmDeleteUser(element?._id)
                                  }
                                >
                                  <i className="mdi mdi-delete"></i>{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>{t("No Records Found")}</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Users);
