import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  forgetPassword,
  verifyOtp,
  resetPassword,
} from "services/userServices";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/short-logo-home.png";
import PasswordTypeComp from "components/Common/PasswordTypeComp";
import { successMsgHandler } from "common/successMsgHandler";

const ResetPassword = ({ t }) => {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");
  const [loader, setLoader] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      new_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const resp = await resetPassword(
          values.new_password,
          localStorage.getItem("token-info")
        );
        if (resp.status == 200) {
          history.push("/login");
          toast.success(t(`${successMsgHandler(resp.message)}`));
          setLoader(false);
        }
      } catch (error) {
        toastCatchError(error);
        setLoader(false);
      } finally {
        setLoader(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/* <i className="bx bx-home h2" /> */}
        </Link>
      </div>
      <div className="account-pages  my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ background: "#317D9D" }}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">{t("Welcome Back")} !</h5>
                        {/* <p className="text-white">
                          Sign in to continue to Fintech Blok.
                        </p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="#" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-4 bg-soft"
                        style={{ height: "4.5rem", width: "5.5rem" }}
                      >
                        <span
                          className="avatar-title rounded border"
                          style={{
                            background: "#fff",
                          }}
                        >
                          <img src={logo} height="55" className="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3 position-relative">
                        <Label className="form-label">
                          {t("Enter New Password")}
                        </Label>
                        <Input
                          name="new_password"
                          className="form-control"
                          placeholder=""
                          type={passwordType}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.new_password || ""}
                          invalid={
                            validation.touched.new_password &&
                            validation.errors.new_password
                              ? true
                              : false
                          }
                        />
                        <PasswordTypeComp
                          passwordType={passwordType}
                          setPasswordType={setPasswordType}
                        />
                        {validation.touched.new_password &&
                        validation.errors.new_password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.new_password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-4 mt-4">
                        <Col className="text-center">
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              style={{
                                background: "#7EE387",
                                border: "#7EE387",
                              }}
                            >
                              {loader ? `${t("Loading")} ...` : t("Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-0 text-center">
                        <p>
                          {t("Go back to")}{" "}
                          <Link
                            to="login"
                            className="font-weight-medium text-primary"
                          >
                            {t("Login Page")}
                          </Link>{" "}
                        </p>
                      </div>
                      <div className="mt-3 text-center">
                        <p>
                          © {new Date().getFullYear()} .{" "}
                          {t("Design & Developed by Block Tech Brew")}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(ResetPassword));
